import { useEffect, useRef, useState } from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import useScreenSize from "../../hooks/useScreenSize";

const Banner = (props) => {
  const imageRef = useRef(null);
  const textRef = useRef(null);
  const underlineRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const screenSize = useScreenSize();

  useEffect(() => {
    if (!imageLoaded) return;
    if (!imageRef) return;
    if (!imageRef.current) return;
    if (!textRef) return;
    if (!textRef.current) return;
    if (!underlineRef) return;
    if (!underlineRef.current) return;
    textRef.current.style.top = `${parseInt(
      (imageRef.current.offsetHeight - textRef.current.offsetHeight) / 2
    )}px`;
    if (imageRef.current.offsetHeight < 272) {
      underlineRef.current.style.top = `${parseInt(
        (imageRef.current.offsetHeight - textRef.current.offsetHeight + 48) / 2
      )}px`;
    } else if (imageRef.current.offsetWidth < 769) {
      underlineRef.current.style.top = `${parseInt(
        (imageRef.current.offsetHeight - textRef.current.offsetHeight + 58) / 2
      )}px`;
    } else if (imageRef.current.offsetWidth < 1025) {
      underlineRef.current.style.top = `${parseInt(
        (imageRef.current.offsetHeight - textRef.current.offsetHeight + 80) / 2
      )}px`;
    } else {
      underlineRef.current.style.top = `${parseInt(
        (imageRef.current.offsetHeight - textRef.current.offsetHeight + 90) / 2
      )}px`;
    }
  }, [screenSize, imageLoaded, imageRef, textRef, underlineRef]);

  return (
    <div className="relative">
      <div className="hidden md:block" ref={imageRef}>
        <img
          className="w-full h-full"
          src="/images/banner.png"
          onLoad={() => {
            setImageLoaded(true);
          }}
        />
      </div>
      <div className="block md:hidden">
        <img
          className="w-full h-full"
          src="/images/banner-mobile.png"
          onLoad={() => {
            setImageLoaded(true);
          }}
        />
      </div>
      <div
        ref={textRef}
        className="hidden md:flex flex-col items-start justify-center my-2 md:my-0 md:absolute md:left-20"
      >
        <div className="md:text-white w-[150px] md:w-[200px] lg:w-[450px]">
          <p className="text-xl lg:text-4xl">
            "Bir anne tüm dünyayı değiştirebilir"
          </p>
        </div>
        <div className="hidden md:block my-5">
          <PrimaryButton
            title={"Biz Kimiz?"}
            onClick={() => {}}
            href={"#about-us"}
          />
        </div>
      </div>
      <div
        ref={underlineRef}
        className="absolute hidden md:block left-[210px] lg:left-[320px] xl:left-[300px]"
      >
        <div className="md:h-[30px] lg:h-[40px] xl:h-[60px]">
          <img className="w-full h-full" src="/images/underline.png" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
