import CardWithRightShadow from "../cards/CardWithRightShadow";
import HorizontalDivider from "../dividers/HorizontalDivider";
import BaseModal from "./BaseModal";

const InfoModal = (props) => {
  const {
    items,
    selectedYear,
    setSelectedYear,
    selectedItemId,
    setSelectedItemId,
    showModal,
    setShowModal,
  } = props;
  const currentItem = items.find((item) => item.id === selectedItemId);
  if (!currentItem) return;
  return (
    <BaseModal
      isOpen={showModal}
      closeModal={() => {}}
      onReturnClick={() => {
        setShowModal(false);
      }}
      title={"ETKİNLİKLER"}
    >
      <div className="h-full flex lg:flex-row flex-col justify-center items-start flex-wrap bg-white">
        <div className="h-8 lg:h-full w-full lg:w-1/6">
          <CardWithRightShadow>
            <div className="h-full flex flex-row lg:flex-col justify-center lg:justify-center items-center gap-x-2 lg:gap-x-0 gap-y-2 flex-wrap mr-8 lg:mr-0">
              {["2024", "2023", "2022"].map((item) => (
                <div
                  className={`${
                    selectedYear === item
                      ? "text-green-dark underline font-bold"
                      : "text-green-light"
                  } cursor-pointer text-center`}
                  onClick={() => {
                    setSelectedYear(item);
                  }}
                >
                  <p>{item}</p>
                </div>
              ))}
            </div>
          </CardWithRightShadow>
        </div>
        <div className="w-full block lg:hidden my-3">
          <HorizontalDivider height={300} />
        </div>
        <div className="h-[200px] lg:h-full w-full lg:w-1/3">
          <div className="h-full mx-0 lg:mx-2">
            <CardWithRightShadow>
              <div className="h-full flex gap-x-2 lg:gap-x-0 gap-y-2 flex-wrap p-4">
                {items.map((item, index) => (
                  <div key={index} className="w-full">
                    <div
                      className={`text-green-light cursor-pointer text-center`}
                      onClick={() => {
                        setSelectedItemId(item.id);
                      }}
                    >
                      <p
                        className={`${
                          selectedItemId === item.id
                            ? "text-green-dark underline font-bold"
                            : "t"
                        }`}
                      >
                        {item.name}
                      </p>
                      <p className="text-sm">{item.date}</p>
                    </div>
                    {index !== items.length - 1 ? (
                      <div className="hidden lg:block my-2">
                        <HorizontalDivider />
                      </div>
                    ) : (
                      <div className="mb-4"></div>
                    )}
                  </div>
                ))}
              </div>
            </CardWithRightShadow>
          </div>
        </div>
        <div className="w-full block lg:hidden my-3">
          <HorizontalDivider height={300} />
        </div>
        <div className="flex-1 lg:h-full overflow-y-auto">
          <div className="p-2 lg:p-8">
            <div className="w-full">
              <img className="w-full h-full" src={currentItem.path} />
            </div>
            <div className="my-5">
              <div className="flex flex-col justify-center items-center text-center">
                <p className="text-green-light font-bold">{currentItem.name}</p>
                <p className="text-sm text-green-light">{currentItem.date}</p>
                <div className="my-2">
                  <p>{currentItem.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default InfoModal;
