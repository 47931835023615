import { useEffect, useRef, useState } from "react";
import SecondaryButton from "../buttons/SecondaryButton";
import HorizontalDivider from "../dividers/HorizontalDivider";
import InputGroup from "../inputs/InputGroup";
import useScreenSize from "../../hooks/useScreenSize";

const FeePayment = (props) => {
  const screenSize = useScreenSize();
  const ref = useRef();
  const absRef = useRef();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!ref) return;
    if (!ref.current) return;
    if (!absRef) return;
    if (!absRef.current) return;
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
  }, [ref, absRef, screenSize]);

  const RenderIyzicoContent = () => {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-col items-center">
          <p>Ödemeniz iyzico güvencesi ile alınacaktır.</p>
          <div className="w-[70px]">
            <img className="w-full h-full" src="/images/iyzico.png" />
          </div>
        </div>
      </div>
    );
  };

  const handleOnChange = () => {};
  return (
    <div className="p-2 lg:p-8" id="fee">
      <div className="relative" ref={ref}>
        <div className="hidden md:flex flex-row items-center justify-center">
          <div className="w-[250px]">
            <div className="flex flex-col justify-center item-center">
              <div className="w-[220px]">
                <img className="w-full h-full" src="/images/credit-card.png" />
              </div>
            </div>
          </div>
          <div className="hidden md:block flex-1">
            <div className="h-full flex flex-col justify-center">
              <p className="text-2xl font-bold">AİDAT ÖDEME</p>
              <p>
                Türk Anneler Derneği üyeleri, aidat ödemelerini aşağıdaki alan
                aracılığıyla yapabilirler.{" "}
              </p>
            </div>
          </div>
          <div className="w-[150px]">
            <div className="flex flex-col justify-center item-center">
              <div className="w-[120px]">
                <img className="w-full h-full" src="/logos/logo.png" />
              </div>
            </div>
          </div>
        </div>

        <div className="flex md:hidden flex-row items-center justify-center">
          <div className="flex-1">
            <div className="flex flex-col justify-center item-center">
              <div className="">
                <img className="w-full h-full" src="/images/credit-card.png" />
              </div>
            </div>
          </div>

          <div className="flex-1">
            <div className="flex flex-col justify-center item-center">
              <div className="">
                <img className="w-full h-full" src="/logos/logo.png" />
              </div>
            </div>
          </div>
        </div>

        <div className="block md:hidden flex-1">
          <div className="h-full flex flex-col justify-center">
            <p className="text-2xl font-bold">AİDAT ÖDEME</p>
            <p>
              Türk Anneler Derneği üyeleri, aidat ödemelerini aşağıdaki alan
              aracılığıyla yapabilirler.{" "}
            </p>
          </div>
        </div>

        <div className="my-5">
          <div className="flex flex-row flex-wrap md:flex-nowrap">
            <div className="w-full flex flex-col lg:flex-row justify-start items-center gap-y-2 my-5 flex-wrap">
              <div className="w-full">
                <div className="mx-2">
                  <InputGroup
                    type="text"
                    onChange={handleOnChange}
                    title={"Üyenin Adı Soyadı"}
                    placeholder={"Üyenin Adı Soyadı"}
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="mx-2">
                  <InputGroup
                    type="text"
                    onChange={handleOnChange}
                    title={"Üyenin Email Adresi"}
                    placeholder={"Üyenin Email Adresi"}
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="mx-2">
                  <InputGroup
                    type="text"
                    onChange={handleOnChange}
                    title={"TC No."}
                    placeholder={"TC No."}
                  />
                </div>
              </div>
              <div className="hidden md:block w-full">
                <div className="my-5">{RenderIyzicoContent()}</div>
              </div>
            </div>
            <div className="hidden lg:block">
              <HorizontalDivider />
            </div>
            <div className="w-full flex flex-col lg:flex-row justify-start items-center gap-y-2 my-5 flex-wrap">
              <div className="w-full">
                <div className="mx-2">
                  <InputGroup
                    type="text"
                    onChange={handleOnChange}
                    title={"Kart üzerindeki Ad Soyad"}
                    placeholder={"Kart üzerindeki Ad Soyad"}
                    iconSrc="/icons/user.png"
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="mx-2">
                  <InputGroup
                    type="text"
                    onChange={handleOnChange}
                    title={"Kart Numarası"}
                    placeholder={"Kart Numarası"}
                    iconSrc="/icons/credit-card.png"
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="mx-2">
                  <InputGroup
                    type="text"
                    onChange={handleOnChange}
                    title={"Son Kullanma Tarihi"}
                    placeholder={"Son Kullanma Tarihi"}
                    iconSrc="/icons/credit-card.png"
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="mx-2">
                  <InputGroup
                    type="text"
                    onChange={handleOnChange}
                    title={"Güvenlik Kodu"}
                    placeholder={"Güvenlik Kodu"}
                    iconSrc="/icons/cvc.png"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="block md:hidden">
            <div className="my-5">{RenderIyzicoContent()}</div>
          </div>

          <div className="flex flex-row justify-center md:justify-end">
            <SecondaryButton title={"Ödeme Yap"} />
          </div>
        </div>
        <div
          ref={absRef}
          className="absolute t-0 bottom-0 bg-gray-600 opacity-90 rounded-lg z-10"
          style={{ width, height }}
        >
          <div className="h-full flex flex-col justify-center items-center">
            <div className="w-[200px] md:w-[300px] lg:w-[400px] text-center text-white">
              <p className="text-xl font-bold">
                Online aidat ödeme özelliği çok yakında kullanımınıza
                açılacaktır.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeePayment;
