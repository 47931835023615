import LandingScreen from "./screens/LandingScreen";

function App() {
  return (
    <div
      className="text-dark bg-green-light text-sm p-3"
      style={{ fontFamily: "Barlow" }}
    >
      <div className="container mx-auto bg-white  rounded-3xl">
        <LandingScreen />
      </div>
    </div>
  );
}

export default App;
