const InputGroup = (props) => {
  const { type, title, placeholder, onChange, iconSrc } = props;

  const TextInputGroup = (props) => {
    return (
      <div className="w-full">
        <div className="mx-4">
          <p className="font-bold">{title}</p>
        </div>
        <div className="relative">
          {iconSrc ? (
            <div className="absolute top-[10px] left-2">
              <div className="w-[25px]">
                <img className="w-full h-full" src={iconSrc} />
              </div>
            </div>
          ) : null}

          <input
            placeholder={placeholder}
            className={`${
              iconSrc ? "pl-10" : ""
            } w-full border-[1px] border-light text-dark rounded-3xl px-5 py-[10px] focus:border-[1px] focus:border-dark`}
            type={type}
            onChange={onChange}
          />
        </div>
      </div>
    );
  };

  const TextareaInputGroup = (props) => {
    return (
      <div className="w-full">
        {title ? (
          <div className="mx-4">
            <p className="font-bold">{title}</p>
          </div>
        ) : null}
        <div className="relative">
          {iconSrc ? (
            <div className="absolute top-[10px] left-2">
              <div className="w-[25px]">
                <img className="w-full h-full" src={iconSrc} />
              </div>
            </div>
          ) : null}

          <textarea
            placeholder={placeholder}
            className={`${
              iconSrc ? "pl-10" : ""
            } w-full border-[1px] border-light text-dark rounded-3xl px-5 py-[10px] focus:border-[1px] focus:border-dark`}
            type={type}
            onChange={onChange}
          />
        </div>
      </div>
    );
  };

  if (type === "text" || type === "password") {
    return TextInputGroup();
  } else if (type === "textarea") {
    return TextareaInputGroup();
  }
  return null;
};

export default InputGroup;
