import { useState } from "react";
import LandingTitle from "../titles/LandingTitle";
import BaseModal from "../modals/BaseModal";
import BaseGallery from "../gallery/BaseGallery";
import usInPressJSON from "../../data/us-in-press.json";

const UsInPress = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const checkCanNext = () => {
    const foundIndex = usInPressJSON.findIndex(
      (item) => item.id === selectedId
    );
    if (foundIndex === usInPressJSON.length - 1) return false;
    return true;
  };

  const handleNextOnClick = () => {
    const foundIndex = usInPressJSON.findIndex(
      (item) => item.id === selectedId
    );
    if (checkCanNext()) setSelectedId(usInPressJSON[foundIndex + 1].id);
  };

  const checkCanPrevious = () => {
    const foundIndex = usInPressJSON.findIndex(
      (item) => item.id === selectedId
    );
    if (foundIndex === 0) return false;
    return true;
  };

  const handlePreviousOnClick = () => {
    const foundIndex = usInPressJSON.findIndex(
      (item) => item.id === selectedId
    );
    if (checkCanPrevious()) setSelectedId(usInPressJSON[foundIndex - 1].id);
  };

  const RenderModalContent = () => {
    return (
      <BaseModal
        isOpen={showModal}
        closeModal={() => {
          setShowModal(false);
          setSelectedId(null);
          setIsFullScreen(false);
        }}
        onReturnClick={() => {
          setShowModal(false);
          setSelectedId(null);
          setIsFullScreen(false);
        }}
        title={"BASINDA BİZ"}
      >
        <BaseGallery
          items={usInPressJSON}
          canNext={checkCanNext()}
          handleNextOnClick={handleNextOnClick}
          canPrevious={checkCanPrevious()}
          handlePreviousOnClick={handlePreviousOnClick}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
        />
      </BaseModal>
    );
  };

  return (
    <>
      {RenderModalContent()}
      <div className="px-8 py-4 md:px-16 md:py-8" id="us-in-press">
        <LandingTitle
          title={"BASINDA BİZ"}
          description={
            "Derneğimizin geçmişten günümüze basındaki haberlerine göz atın."
          }
        />
        <div className="mt-10">
          <div
            className="w-full cursor-pointer"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <img className="w-full h-full" src="/images/us-in-press.png" />
          </div>
        </div>
      </div>
    </>
  );
};

export default UsInPress;
