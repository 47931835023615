const CardWithShadow = (props) => {
  const { children } = props;

  return (
    <div
      className="rounded-3xl"
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
      }}
    >
      {children}
    </div>
  );
};

export default CardWithShadow;
