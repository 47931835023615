import { useState } from "react";
import VerticalDivider from "../dividers/VerticalDivider";

const LandingHeader = (props) => {
  const [showMenu, setShowMenu] = useState(false);

  const HeaderItem = (type, value, anchor) => {
    if (type === "TEXT") {
      return (
        <a href={anchor} className="cursor-pointer">
          <p>{value}</p>
        </a>
      );
    }
    return null;
  };

  return (
    <div className="relative p-4 md:p-8">
      <div className="absolute left-5 top-9 lg:top-5 z-10">
        <div className="w-20 md:w-24 lg:w-32">
          <img className="w-full h-full" src="./logos/logo.png" />
        </div>
      </div>

      <div className="flex flex-row md:hidden justify-end items-center">
        <div
          className="w-10 h-10 cursor-pointer"
          onClick={() => {
            if (showMenu) {
              setShowMenu(false);
            } else {
              setShowMenu(true);
            }
          }}
        >
          <img className="w-full h-full" src="/icons/menu.png" />
        </div>
      </div>

      {showMenu ? (
        <div className="flex flex-col justify-center items-center gap-y-2">
          {HeaderItem("TEXT", "Ana sayfa", "/")}
          {HeaderItem("TEXT", "Hakkımızda", "#about-us")}
          {HeaderItem("TEXT", "Etkinlikler", "#events")}
          {HeaderItem("TEXT", "Basında Biz", "#us-in-press")}
          {HeaderItem("TEXT", "Duyurular", "#announcements")}
          {HeaderItem("TEXT", "Aidat", "#fee")}
          {HeaderItem("TEXT", "İletişim", "#footer")}
        </div>
      ) : null}

      <div className="hidden md:flex flex-row justify-end items-center gap-x-4 ">
        {HeaderItem("TEXT", "Ana sayfa", "/")}
        <VerticalDivider />
        {HeaderItem("TEXT", "Hakkımızda", "#about-us")}
        <VerticalDivider />
        {HeaderItem("TEXT", "Etkinlikler", "#events")}
        <VerticalDivider />
        {HeaderItem("TEXT", "Basında Biz", "#us-in-press")}
        <VerticalDivider />
        {HeaderItem("TEXT", "Duyurular", "#announcements")}
        <VerticalDivider />
        {HeaderItem("TEXT", "Aidat", "#fee")}
        <VerticalDivider />
        {HeaderItem("TEXT", "İletişim", "#footer")}
      </div>
    </div>
  );
};

export default LandingHeader;
