const AboutUs = (props) => {
  const FounterItem = (src, interval, firstName, lastName, role) => {
    return (
      <div className="flex flex-row gap-y-2 z-10 mx-2">
        <div className="w-[50px] md:w-[80px] h-[70px] md:h-[100px] ">
          <img className="w-full h-full object-cover" src={src} />
        </div>
        <div className="w-[110px]">
          <div className="h-full flex flex-col items-center">
            <div className="p-3">
              <p className="mb-4 text-green-light">{interval}</p>
              <p>{firstName}</p>
              <p>{lastName}</p>
              <p className="italic opacity-70">{role ? `(${role})` : null}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const AboutUsContent = () => {
    return (
      <div className="md:mr-[200px] mt-5">
        <div className="mb-5">
          <p className="font-bold text-2xl">Hakkımızda</p>
        </div>
        <p>
          Türk Anneler Derneği 1959 yılında kurulmuştur. 1964 tarihinde Kamu
          yararına çalışma statüsü kazanmış bir dernektir. Dernek{" "}
          <span className="text-green-light">“ana”</span> olan kadının maddi ve
          manevi yönden kalkındırılması, eğitilerek aydınlatılması suretiyle
          çocuğunun Atatürk ilke ve inkılâplarına bağlı topluma yararlı, üretken
          mutlu birer vatandaş olarak yetiştirilmesini sağlamak amacıyla
          kurulmuş olup, Türk kadınının sosyal, kültürel, eğitim düzeyini
          yükseltmek ve çağdaşlaşma yolunda çeşitli çalışmalar yapmakta,
          Seminer, Panel, Konferans ve Yardım Kampanyası gibi etkinlikler
          düzenlemektedir. Dernek genel merkezi Ankara ilinde olup, ülkemiz
          genelinde 58 şubesi ile birlikte faaliyet göstermektedir. Başta deprem
          bölgelerimizde ki şehirlerimiz olmak üzere  yoğun bir şekilde çeşitli
          faaliyet ve etkinlikler düzenleyen, yardıma ihtiyacı olana kol kanat
          geren ve geleceğimizin aydın yüzü olan öğrencilerimize burs imkânları
          sunan Türk Anneler Derneği, Türkiye’nin pek çok yerinde insanların
          yaşamlarına dokunuyor.
        </p>
      </div>
    );
  };

  const FounderContent = () => {
    return (
      <div className="">
        <div className="mb-5">
          <p className="font-bold text-2xl">Genel Başkanımız</p>
        </div>
        <div className="flex flex-row justify-start items-center flex-wrap gap-y-2">
          {FounterItem(
            "/images/about-us/person-1.jpg",
            "2022 - Şimdi",
            "Handan",
            "Çakıroğlu",
            null
          )}
        </div>
      </div>
    );
  };

  const FirstFounderContent = () => {
    return (
      <div className="">
        <div className="mb-5">
          <p className="font-bold text-2xl">
            İlk Başkanlarımız{" "}
            <span className="text-green-light text-base font-light">
              (1959-2002)
            </span>
          </p>
        </div>
        <div className="flex flex-row justify-start flex-wrap gap-y-2">
          {FounterItem(
            "/images/about-us/person-2.jpg",
            "1959 - 1961",
            "Halide Nusret",
            "Zorlutuna",
            "Şair, Yazar"
          )}
          {FounterItem(
            "/images/about-us/person-3.jpg",
            "1961 - 1970",
            "Şeminur",
            "İnanç",
            "Avukat"
          )}
          {FounterItem(
            "/images/about-us/person-4.jpg",
            "1970 - 2002",
            "Türkan",
            "Aksu",
            null
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="" id="about-us">
      <div className="flex flex-row items-center flex-wrap">
        <div className="block w-full xl:hidden">
          <div className="w-full">
            <img className="h-full w-full" src="/images/together-small.png" />
          </div>
        </div>
        <div className="hidden xl:block xl:w-[450px]">
          <div className="w-full">
            <img className="h-full w-full" src="/images/together.png" />
          </div>
        </div>
        <div className="flex-1">
          <div className="flex flex-col gap-y-4">
            <div className="relative">
              <div className="hidden md:block absolute right-0">
                <div className="w-[200px]">
                  <img className="w-full h-full" src="/logos/right-logo.png" />
                </div>
              </div>
              {AboutUsContent()}
            </div>
            <div className="mb-5">
              {FounderContent()}
              {FirstFounderContent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
