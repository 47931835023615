const LandingTitle = (props) => {
  const { title, description } = props;

  return (
    <div className="w-full lg:w-1/2 text-center mx-auto">
      <div className="flex flex-col justify-center items-center">
        <p className="text-green-light text-2xl">{title}</p>
        <p className="">{description}</p>
      </div>
    </div>
  );
};

export default LandingTitle;
