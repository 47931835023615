import SecondaryButton from "../buttons/SecondaryButton";
import HorizontalDivider from "../dividers/HorizontalDivider";
import VerticalDivider from "../dividers/VerticalDivider";
import InputGroup from "../inputs/InputGroup";

const LandingFooter = (props) => {
  const FooterItem = (type, value, anchor) => {
    if (type === "TEXT") {
      return (
        <a href={anchor} className="cursor-pointer">
          <p className="opacity-50">{value}</p>
        </a>
      );
    }
    return null;
  };

  const handleOnChange = () => {};

  const RenderCopyrightContent = () => {
    return (
      <div className="flex flex-row">
        <div className="flex-1">
          <p className="opacity-50">
            Copyright © 2024 Turk Anneler Dernegi | All Rights Reserved
          </p>
        </div>
        <div className="flex-1 flex flex-row justify-end items-center gap-x-2">
          <div className="w-10 h-10">
            <img className="w-full h-full" src="/icons/facebook.png" />
          </div>
          <div className="w-10 h-10">
            <img className="w-full h-full" src="/icons/instagram.png" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="px-8 py-4 md:px-16 md:py-8" id="footer">
      <div className="w-full flex flex-col justify-center items-center gap-y-5">
        <div className="flex flex-row justify-center items-center gap-x-10 flex-wrap">
          <div className="w-full lg:w-1/3 flex flex-col gap-y-5">
            <div className="flex flex-row justify-center items-center gap-y-2 md:gap-x-4 flex-wrap my-4 md:my-0">
              <div className="w-full flex flex-col justify-center items-center">
                <div className="w-[80px] md:w-[120px]">
                  <img w-full h-full src="/logos/logo.png" />
                </div>
              </div>
              <div className="flex-1 flex flex-col justify-center items-center">
                <p className="mb-5 font-bold">Menü</p>
                <div className="flex flex-row flex-wrap justify-center items-center gap-x-2 gap-y-2">
                  {FooterItem("TEXT", "Ana sayfa", "/")}
                  <VerticalDivider />
                  {FooterItem("TEXT", "Hakkımızda", "#about-us")}
                  <VerticalDivider />
                  {FooterItem("TEXT", "Etkinlikler", "#events")}
                  <VerticalDivider />
                  {FooterItem("TEXT", "Basında Biz", "#us-in-press")}
                  <VerticalDivider />
                  {FooterItem("TEXT", "Duyurular", "#announcements")}
                  <VerticalDivider />
                  {FooterItem("TEXT", "Aidat", "#fee")}
                </div>
              </div>
            </div>
            <div className="hidden lg:block">
              <HorizontalDivider />
            </div>
            <div className="hidden lg:block">{RenderCopyrightContent()}</div>
          </div>

          <div className="flex-1 flex flex-col justify-center mt-4">
            <div className="mx-auto">
              <iframe
                className="rounded-3xl"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12239.271811977183!2d32.8419702757521!3d39.92309000046614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34effe9b4cabd%3A0x61db2ee7c97ffe2f!2sT%C3%BCrk%20Anneler%20Derne%C4%9Fi!5e0!3m2!1sen!2str!4v1716311320303!5m2!1sen!2str"
                width="300"
                height="350"
                style={{
                  border: 0,
                }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="flex-1">
            <div className="my-4">
              <p className="font-bold text-center md:text-left">İletişim</p>
            </div>
            <div className="my-1">
              <div className="flex flex-row gap-x-2">
                <div className="w-[20px] h-[20px]">
                  <img className="w-full h-full" src="/icons/map.png" />
                </div>
                <div className="flex-1">
                  <p className="opacity-50">
                    Kızılay, Menekşe-1 Sk. No:8, 06420 Çankaya/Ankara
                  </p>
                </div>
              </div>
            </div>
            <div className="my-1">
              <div className="flex flex-row gap-x-2">
                <div className="w-[20px] h-[20px]">
                  <img className="w-full h-full" src="/icons/phone.png" />
                </div>
                <div className="flex-1">
                  <p className="opacity-50">0 (312) 425 5818</p>
                </div>
              </div>
            </div>

            {/* <div className="mt-5 mb-1">
              <p className="font-bold text-center md:text-left">Bize Yazın</p>
            </div>
            <div className="flex flex-row items-center justify-center my-2 flex-wrap">
              <div className="w-full lg:w-1/2">
                <div className="lg:mx-2">
                  <InputGroup
                    type="text"
                    onChange={handleOnChange}
                    placeholder={"Ad Soyad"}
                  />
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <div className="lg:mx-2">
                  <InputGroup
                    type="text"
                    onChange={handleOnChange}
                    placeholder={"E-Mail"}
                  />
                </div>
              </div>
            </div>
            <div className="my-2">
              <div className="lg:mx-2">
                <InputGroup
                  type="textarea"
                  onChange={handleOnChange}
                  placeholder={"Mesajınız"}
                />
              </div>
            </div>
            <div className="flex flex-row justify-end">
              <SecondaryButton title={"Gönder"} />
            </div> */}
          </div>
        </div>
        <div className="block lg:hidden">
          <HorizontalDivider />
        </div>
        <div className="block lg:hidden">{RenderCopyrightContent()}</div>
      </div>
    </div>
  );
};

export default LandingFooter;
