const CardWithRightShadow = (props) => {
  const { children, isRounded } = props;

  return (
    <div
      className={`${isRounded ? "rounded-3xl" : "rounded-r-lg"} h-full`}
      style={{ boxShadow: "5px 0 5px -5px #333" }}
    >
      <div className="h-full flex flex-row items-center">
        <div className="max-h-full flex-1 overflow-y-auto">{children}</div>
        <div className="hidden lg:block mx-2">
          <div className="w-5 h-5">
            <img className="w-full h-full" src="/icons/chevron-right.png" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardWithRightShadow;
