const SecondaryButton = (props) => {
  const { title, onClick, href } = props;
  return (
    <a className="flex flex-row cursor-pointer" onClick={onClick} href={href}>
      <div className="flex flex-row bg-green-dark border-2 border-green-dark text-white rounded-3xl px-4 py-2 gap-x-2 items-center hover:bg-green-light hover:border-green-light transition-all duration-300">
        <p>{title}</p>
        <div className="w-[10px]">
          <img className="w-full h-full" src="/icons/arrow-right-2.png" />
        </div>
      </div>
    </a>
  );
};

export default SecondaryButton;
