const BaseGallery = (props) => {
  const {
    items,
    canNext,
    handleNextOnClick,
    canPrevious,
    handlePreviousOnClick,
    selectedId,
    setSelectedId,
    isFullScreen,
    setIsFullScreen,
  } = props;

  return (
    <div className="lg:flex-1 h-full">
      <div className="h-full p-2 md:p-4 lg:p-6 xl:p-8 relative">
        {isFullScreen ? (
          <>
            <div className="absolute -top-12 lg:-top-16 left-5">
              <div
                className="w-8 bg-white rounded-full p-1 cursor-pointer"
                onClick={() => {
                  setSelectedId(null);
                  setIsFullScreen(false);
                }}
              >
                <img className="w-full h-full" src="/icons/arrow-left.png" />
              </div>
            </div>
            {canPrevious ? (
              <div className="absolute left-5 top-1/2 z-10">
                <div
                  className="w-8 bg-white rounded-full p-1 cursor-pointer"
                  onClick={handlePreviousOnClick}
                >
                  <img
                    className="w-full h-full"
                    src="/icons/chevron-green-left.png"
                  />
                </div>
              </div>
            ) : null}
            {canNext ? (
              <div className="absolute right-5 top-1/2 z-10">
                <div
                  className="w-8 bg-white rounded-full p-1 cursor-pointer"
                  onClick={handleNextOnClick}
                >
                  <img
                    className="w-full h-full"
                    src="/icons/chevron-green-right.png"
                  />
                </div>
              </div>
            ) : null}
          </>
        ) : null}
        <div className="h-full overflow-y-auto">
          <div className="h-full flex flex-col justify-center items-center">
            {isFullScreen ? (
              <div className="w-full h-auto lg:w-auto lg:h-full relative">
                <img
                  className="w-full h-full"
                  src={items.find((item) => item.id === selectedId)?.path}
                />
              </div>
            ) : (
              <div className="h-full flex flex-row justify-center items-center flex-wrap">
                {items.map((item, index) => (
                  <div key={index} className="p-1">
                    <div
                      className="w-[200px] lg:w-[200px] h-[200px] lg:h-[200px] cursor-pointer"
                      onClick={() => {
                        setSelectedId(item.id);
                        setIsFullScreen(true);
                      }}
                    >
                      <img
                        className="w-full h-full object-cover lg:rounded-3xl"
                        src={item.path}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseGallery;
