const CardWithLessShadow = (props) => {
  const { children } = props;

  return (
    <div className="rounded-3xl border" style={{ borderColor: "#DCDCDC" }}>
      {children}
    </div>
  );
};

export default CardWithLessShadow;
