import { useState } from "react";
import SecondaryButton from "../buttons/SecondaryButton";
import HorizontalDivider from "../dividers/HorizontalDivider";
import BaseModal from "../modals/BaseModal";

const branchList = [
  "Ankara (Genel Merkez)",
  "İskenderun",
  "Küçükçekmece",
  "Ceyhan",
  "Mersin Akdeniz",
  "Zonguldak",
  "Osmaniye",
  "Mersin Yenişehir",
  "Küçükköy",
  "Kadirli",
  "Eğirdir",
  "Balıkesir",
  "Gaziantep",
  "Hatay",
  "Altınova",
  "Erzurum",
  "Uşak",
  "Ayvalık",
  "Konya",
  "Edremit",
  "Nazilli",
  "Kayseri",
  "Isparta",
  "Edremit",
  "Ereğli",
  "Bursa",
  "Çaycuma",
  "Antalya",
  "Trabzon",
  "Kartal",
  "Kırklareli",
  "Afyon",
  "Şişli",
  "Tekirday",
  "Maltepe",
  "Kars",
  "Çanakkale",
  "Çankırı",
  "İzmir",
  "Aliağa",
  "Karşıyaka",
  "Beşiktaş",
  "Güzelbahçe",
  "Fatih",
  "Mordoğan",
  "Eminönü",
  "Çeşme",
  "Alaplı",
  "Kastamonu",
  "İzmit",
  "Aksaray",
  "Burhaniye",
];

const JoinUs = () => {
  const [showModal, setShowModal] = useState(false);

  const RenderShowBranchListModal = () => {
    return (
      <BaseModal
        isOpen={showModal}
        closeModal={() => {
          setShowModal(false);
        }}
        onReturnClick={() => {
          setShowModal(false);
        }}
        title={"ŞUBELERİMİZ"}
      >
        <div className="h-full py-5">
          <div className="h-full flex flex-row flex-wrap overflow-y-auto">
            {branchList.map((branch) => (
              <div className="w-full md:w-1/2 lg:w-1/3">
                <div className="flex flex-col justify-center items-center">
                  <p>{branch}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </BaseModal>
    );
  };

  return (
    <>
      {RenderShowBranchListModal()}
      <div className="px-8 py-4 md:px-16 md:py-8">
        <div className="flex flex-row items-center bg-yellow p-6 border-2 border-yellow rounded-3xl flex-wrap gap-y-4">
          <div className="w-full md:w-[150px]">
            <div className="flex flex-col justify-center items-center">
              <div className="w-[120px]">
                <img className="w-full h-full" src="/logos/logo.png" />
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="flex flex-col gap-y-4">
              <p className="text-lg font-bold">
                Derneğimize katılmak ister misiniz?
              </p>
              <HorizontalDivider color={"bg-black"} />
              <p className="">
                Medeni hakkını kullanma ehliyetine sahip 18 yaşını bitirmiş her
                Türk kadını, iki üyenin tavsiyesi ile üyelik formu doldurmak,
                yönetim kurulu tarafından kabul edilmek, yıllık üyelik aidatını
                ödemek şartı ile üye olabilir.Her üyenin bir oy hakkı vardır ve
                eşit hakka sahiptir. Üyelerimizde anne olma şartı
                aranmamaktadır. 58 şubemizle faliyetlerimizi sürdürmekteyiz.
              </p>
            </div>
            <div className="block lg:hidden my-5">
              <SecondaryButton
                title="Şubelerimiz"
                onClick={() => {
                  setShowModal(true);
                }}
              />
            </div>
          </div>
          <div className="hidden lg:block w-[250px]">
            <div className="flex flex-col justify-center items-center">
              <SecondaryButton
                title="Şubelerimiz"
                onClick={() => {
                  setShowModal(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinUs;
