import LandingTitle from "../titles/LandingTitle";
import { useEffect, useState } from "react";
import BaseModal from "../modals/BaseModal";
import BaseGallery from "../gallery/BaseGallery";
import CardWithRightShadow from "../cards/CardWithRightShadow";
import galleryJSON from "../../data/gallery.json";
import useScreenSize from "../../hooks/useScreenSize";

const Gallery = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedId, setSelectedId] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [itemCount, setItemCount] = useState(12);
  const screenSize = useScreenSize();

  useEffect(() => {
    const { width } = screenSize;
    if (width <= 450) {
      setItemCount(12);
    } else if (width <= 768) {
      setItemCount(12);
    } else if (width <= 1024) {
      setItemCount(12);
    } else if (width <= 1440) {
      setItemCount(15);
    } else {
      setItemCount(14);
    }
  }, [screenSize]);

  const filterItemListByYear = () => {
    return galleryJSON.filter((item) => item.year === selectedYear);
  };

  const checkCanNext = () => {
    if (
      selectedYear === "2024" &&
      filteredItemList.findIndex((item) => item.id === selectedId) ===
        filteredItemList.length - 1
    ) {
      return false;
    }
    return true;
  };

  const checkCanPrevious = () => {
    if (
      selectedYear === "2022" &&
      filteredItemList.findIndex((item) => item.id === selectedId) === 0
    ) {
      return false;
    }
    return true;
  };

  const handleNextOnClick = () => {
    const foundIndex = filteredItemList.findIndex(
      (item) => item.id === selectedId
    );
    if (foundIndex === filteredItemList.length - 1) {
      if (selectedYear === "2024") return;
      const nextYear = (parseFloat(selectedYear) + 1).toString();
      setSelectedYear(nextYear);
      const newFilteredItemList = galleryJSON.filter(
        (item) => item.year === nextYear
      );
      setSelectedId(newFilteredItemList[0].id);
    } else {
      setSelectedId(filteredItemList[foundIndex + 1].id);
    }
  };

  const handlePreviousOnClick = () => {
    const foundIndex = filteredItemList.findIndex(
      (item) => item.id === selectedId
    );
    if (foundIndex === 0) {
      if (selectedYear === "2024") return;
      const previousYear = (parseFloat(selectedYear) - 1).toString();
      setSelectedYear(previousYear);
      const newFilteredItemList = galleryJSON.filter(
        (item) => item.year === previousYear
      );
      setSelectedId(newFilteredItemList[newFilteredItemList.length - 1].id);
    } else {
      setSelectedId(filteredItemList[foundIndex - 1].id);
    }
  };

  const RenderSingleImageItem = (item) => {
    return (
      <div
        className="w-[92px] lg:w-[192px] h-[92px] lg:h-[192px] cursor-pointer"
        onClick={() => {
          setSelectedId(item.id);
          setSelectedYear(item.year);
          setShowModal(true);
          setIsFullScreen(true);
        }}
      >
        <img className="w-full h-full object-cover" src={item.path} />
      </div>
    );
  };

  const RenderGalleryModal = () => {
    return (
      <BaseModal
        isOpen={showModal}
        closeModal={() => {
          setShowModal(false);
        }}
        onReturnClick={() => {
          setShowModal(false);
          setSelectedId(null);
          setIsFullScreen(false);
        }}
        title={"GALERİ"}
      >
        <div className="h-full flex flex-row justify-center items-start flex-wrap bg-white">
          {isFullScreen ? null : (
            <div className="h-8 lg:h-full w-full lg:w-1/6">
              <CardWithRightShadow>
                <div className="h-full flex flex-row lg:flex-col justify-center lg:justify-center items-center gap-x-2 lg:gap-x-0 gap-y-2 flex-wrap mr-8 lg:mr-0">
                  {["2024", "2023", "2022"].map((item) => (
                    <div
                      className={`${
                        selectedYear === item
                          ? "text-green-dark underline font-bold"
                          : "text-green-light"
                      } cursor-pointer text-center`}
                      onClick={() => {
                        setSelectedYear(item);
                      }}
                    >
                      <p>{item}</p>
                    </div>
                  ))}
                </div>
              </CardWithRightShadow>
            </div>
          )}

          <BaseGallery
            items={filteredItemList}
            canNext={checkCanNext()}
            handleNextOnClick={handleNextOnClick}
            canPrevious={checkCanPrevious()}
            handlePreviousOnClick={handlePreviousOnClick}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
          />
        </div>
      </BaseModal>
    );
  };

  const filteredItemList = filterItemListByYear();

  return (
    <>
      {RenderGalleryModal()}
      <div className="px-8 py-4 md:px-16 md:py-8">
        <LandingTitle
          title={"GALERİ"}
          description={"Etkinliklerimizden kareler"}
        />
        <div className="my-5">
          <div className="flex flex-row flex-wrap justify-center gap-y-2 gap-x-2">
            {galleryJSON
              .slice(galleryJSON.length - itemCount, galleryJSON.length)
              .map((item) => RenderSingleImageItem(item))}
          </div>
        </div>
        <div className="my-5">
          <div className="w-full md:w-1/2 m-auto">
            <div className="flex flex-col justify-center items-center">
              <div
                className="cursor-pointer"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <p className="underline opacity-50">DAHA FAZLA</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
