import { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import useScreenSize from "../../hooks/useScreenSize";

const BaseModal = (props) => {
  const { isOpen, onAfterOpen, closeModal, children, onReturnClick, title } =
    props;
  const [height, setHeight] = useState(0);
  const screenSize = useScreenSize();

  useEffect(() => {
    const { width } = screenSize;
    if (width <= 450) {
      setHeight("90%");
    } else if (width <= 768) {
      setHeight("80%");
    } else {
      setHeight("70%");
    }
  }, [screenSize]);

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: 0,
          borderRadius: 20,
          background: "",
          border: "",
          overflow: "auto",
          height,
        },
        overlay: {
          background: "rgba(0, 0, 0, 0.6)",
          zIndex: 1001,
        },
      }}
      contentLabel=""
    >
      <div className="h-full md:w-[700px] lg:w-[900px] xl:w-[1200px] 2xl:w-[1200px] relative bg-white">
        <div className="h-[68px] lg:h-[108px] flex flex-col justify-center items-center px-2 py-4 lg:px-4 lg:py-8">
          <p className="text-green-light text-xl md:text-2xl lg:text-3xl">
            {title}
          </p>
        </div>
        <div className="h-[calc(100%-68px)] lg:h-[calc(100%-108px)]">
          {children}
        </div>
        <div
          className="absolute top-5 lg:top-9 right-10 cursor-pointer z-10 bg-white rounded-3xl p-1"
          onClick={onReturnClick}
        >
          <img className="w-6 h-6" src="/icons/cross.png" />
        </div>
      </div>
    </Modal>
  );
};

export default BaseModal;
