import Carousel from "react-multi-carousel";
import LandingTitle from "../titles/LandingTitle";
import { useEffect, useState } from "react";
import CardWithShadow from "../cards/CardWithShadow";
import eventsJSON from "../../data/events.json";
import InfoModal from "../modals/InfoModal";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Events = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [itemCount, setItemCount] = useState(10);

  useEffect(() => {
    setSelectedEventId(
      eventsJSON.filter((item) => item.year === selectedYear)[0].id
    );
  }, [selectedYear]);

  const filterItemListByYear = () => {
    return eventsJSON.filter((item) => item.year === selectedYear);
  };

  const convertDescription = (description) => {
    if (description.length > 90) {
      return description.substring(0, 100) + "...";
    }
    return description;
  };

  const EventItem = (item) => {
    const { name, date, description, path } = item;
    return (
      <div className="m-2">
        <CardWithShadow>
          <div className="flex flex-col p-4">
            <div className="h-[150px]">
              <img className="w-full h-full object-cover" src={path} />
            </div>
            <div className="my-4">
              <p className="text-green-light text-md font-bold">{name}</p>
              <p className="text-green-light text-sm">{date}</p>
              <p className="mt-3 h-[40px]">{convertDescription(description)}</p>
              <div
                className="flex flex-row cursor-pointer mt-5 items-center gap-x-2"
                onClick={() => {
                  setSelectedEventId(item.id);
                  setSelectedYear(item.year);
                  setShowModal(true);
                }}
              >
                <p className="font-bold underline text-green-light">
                  Daha fazla öğren
                </p>
                <div className="w-5 h-5">
                  <img
                    className="w-full h-full"
                    src="/icons/arrow-right-4.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </CardWithShadow>
      </div>
    );
  };

  const filteredItemList = filterItemListByYear();

  return (
    <>
      <InfoModal
        items={filteredItemList}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        selectedItemId={selectedEventId}
        setSelectedItemId={setSelectedEventId}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <div className="px-8 py-4 md:px-16 md:py-8" id="events">
        <LandingTitle
          title={"ETKİNLİKLER"}
          description={
            "Derneğimiz kapsamındaki yenilik, değişiklik ve güncellemelere buradan ulaşabilirsiniz."
          }
        />
        <div className="my-5">
          <Carousel responsive={responsive}>
            {eventsJSON
              .slice(eventsJSON.length - itemCount, eventsJSON.length)
              .map((item) => (
                <div key={item.id}>{EventItem(item)}</div>
              ))}
          </Carousel>
        </div>
        <div className="mt-10">
          <div className="w-full md:w-1/2 m-auto">
            <div className="flex flex-col justify-center items-center">
              <div
                className="cursor-pointer"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <p className="underline opacity-50">DAHA ESKİ ETKİNLİKLER</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Events;
